<template>
  <div class="root">
    <div class="child">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>


export default {
  name: 'App',
  components: {
    //Mchat,
  },


  mounted: function () {
    this.$router.push({path: "/pages/mchat"});
  },

  provide() {
    return {
      app: this
    };
  },



  methods: {
  }
}
</script>

<style>
#app {
  font-family: 'Microsoft YaHei',  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

html,
body {
  margin: 0;
  padding: 0;
}

.root {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}




.child {
  width: 100%;
  height: 100%;
  flex: 1;
}


::-webkit-scrollbar {
  width: 6px !important;
  height: 8px !important;
}


</style>
